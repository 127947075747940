@import "../../utils/style-constants";

.ResidentView__basic__details__container {
  padding: 20px;
  height: 100%;
}

.ResidentView__section__divider {
  width: 100%;
  height: 14px;
  border-bottom: 1px solid #000000de;
  text-align: left;
  padding-left: 20px;
  color: #000000de;
}

.ResidentView__section__divider__span {
  font-size: 18px;
  background-color: $white;
  padding: 0 10px;
}

.CanPrint__section__divider {
  width: 100%;
  height: 14px;
  border-bottom: 1px solid #000000de;
  text-align: left;
  padding-left: 20px;
  margin-top: -25px;
  margin-bottom: 30px;
  color: #000000de;
}

.Resident__List {
  .resident__clear__button {
    height: 77px;
  }
}
