@import "../../utils/style-constants";

.Login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    111deg,
    #29b8e4 0.66%,
    rgba(238, 105, 62, 0.45) 49.49%,
    rgba(249, 200, 1, 0.26) 97.71%
  );
}

.Login__header {
  color: $deep_ocean_blue;
  font-weight: bold;
  font-size: 35px;
  padding: 10px;
  text-align: center;
}

.Login__form {
  height: 300px;
  width: 480px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25px 0px;
  position: relative;
}

.Login__input__Wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 10px;
}

.Login__input__field {
  background-color: white;
  width: 400px;
  height: 40px;
  border-radius: 0.25em;
  text-align: center;
  font-size: 0.75em;
  border: 1px solid $deep_ocean_blue;
  color: $deep_ocean_blue;
  border-radius: 1em;
  &::placeholder {
    font-size: 15px;
  }
  &:focus {
    outline-color: $deep_ocean_blue;
  }
}

.Login__input__error {
  position: absolute;
  bottom: -15px;
}

.Login__eyeicon__wrapper {
  position: relative;
}

.Login__eyeicon__button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 8px;
}

.Login__submit__button {
  width: 100px;
  height: 50px;
  flex-shrink: 0;
  color: $white;
  text-align: center;
  border-radius: 30px;
  background: $deep_ocean_blue;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border: $white;
}

.Login__error__span {
  position: absolute;
  bottom: 10px;
}
