@import "../../utils/style-constants";

body {
  font-family: "DM Sans", sans-serif !important;
}

.Layout__container {
  display: flex;
}

.Layout__header__container {
  background-color: $deep_ocean_blue;
  color: #d2f5ff;
}

.Layout__header__toolbar {
  padding-right: 24px;
  background-color: $deep_ocean_blue;
  color: #d2f5ff;
}

.Layout__header__logo__container {
  flex-grow: 1;
  font-size: 30;
  letter-spacing: 3px;
  background-color: $deep_ocean_blue;
  color: #d2f5ff;
}

.Layout__drawer {
  height: 100vh;
}

.Layout__home__icon__container {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.Layout__home__icon {
  cursor: pointer;
  width: 100px;
  height: auto;
}

.Layout__menu__item__text {
  color: $white;
}

.Layout__main__component__container {
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $deep_ocean_blue;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
