@import "./utils/style-constants";

body {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.form {
  .MuiFormControl-root {
    height: 60px;
  }
}

.MuiFormControl-root:hover,
.MuiFormControl-root:focus,
.MuiFormControl-root:focus-within,
.MuiFormControl-root label.Mui-focused,
.MuiFormControl-root:active {
  &:not(.Mui-error) {
    label {
      color: $deep_ocean_blue !important;
    }
  }
}

.MuiFormControl-root,
.MuiFormControl-root:hover,
.MuiFormControl-root:focus,
.MuiFormControl-root:focus-within,
.MuiFormControl-root label.Mui-focused,
.MuiFormControl-root:active {
  &:has(.Mui-error) {
    label {
      color: #d32f2f !important;
    }
  }
}

.MuiFormControl-root:hover,
.MuiInputBase-root.Mui-focused {
  &:not(.Mui-error) {
    fieldset {
      border-color: $deep_ocean_blue !important;
    }
  }
}

.MuiFormControl-root,
.MuiFormControl-root:hover,
.MuiFormControl-root:focus-within,
.MuiInputBase-root.Mui-focused {
  &:has(.Mui-error) {
    fieldset {
      border-color: #d32f2f !important;
    }
  }
}

.MuiBadge-badge {
  color: white;
  background-color: $deep_ocean_blue;
  margin-right: -10px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: $deep_ocean_blue !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $deep_ocean_blue !important;
}

.MuiRadio-root.Mui-checked {
  color: $deep_ocean_blue !important;
}

.MuiFormLabel-asterisk {
  color: red;
}

.Mui-checked {
  color: $deep_ocean_blue !important;
}

.container {
  margin: 30px 30px;
  padding: 20px;
}

.filter__paper__container {
  width: 500px;
  padding: 25px;
}

.filter__icon__button {
  padding: 2px !important;
}

.filter__icon {
  font-size: 35px !important;
}

.paper__container {
  margin: 30px 30px;
  padding: 20px;
}

.breadcrumb__link {
  color: $deep_ocean_blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.submit__button {
  background-color: $deep_ocean_blue !important;
  color: white; // Ensures text is visible

  &:hover {
    background-color: #002237 !important;
  }

  &:disabled {
    background-color: #d3d3d3 !important; // Light gray for disabled state
    color: #a9a9a9 !important; // Dimmed gray text
    cursor: not-allowed; // Shows a "not allowed" cursor
  }
}

.cancel__button {
  border-color: $deep_ocean_blue !important;
  color: $deep_ocean_blue !important;
}

.cancel__button:hover {
  border-color: #002237 !important;
  background-color: #f0f0f0 !important;
}

.label__fontsize {
  font-size: 14px !important;
}

.only__available {
  color: rgba($color: #000000, $alpha: 0.7) !important;
}

.Flagged__By__Name {
  color: black;
  text-decoration: underline black;
  &:hover {
    color: $deep_ocean_blue;
    text-decoration: underline $deep_ocean_blue;
  }
}
.service__provider__form__heading {
  color: $deep_ocean_blue;
  text-align: center;
  margin-top: 0;
}

.form__success__message__container {
  height: 100%;
  .form__success__message {
    color: $deep_ocean_blue;
    text-align: center;
    margin-top: 15%;
  }
}

.report__navigation__icon {
  transform: rotate(90deg) !important;
  color: $deep_ocean_blue;
}

.loader {
  opacity: 1 !important;
  visibility: visible !important;
}

.data__opacity {
  opacity: 0;
}

.phone-code {
  .MuiInputBase-sizeSmall {
    height: 40px !important;
  }
}

.resident__clear__button {
  display: flex;
  justify-content: flex-end;
}

.Nivasi__Loader {
  width: 40px;
  height: auto;
}

.report_button {
  height: "43px" !important;
  width: "150px" !important;
  margin-top: "10px" !important;
  background-color: $deep_ocean_blue !important;
  color: white; // Ensures text is visible
  display: flex;

  &:hover {
    background-color: #002237 !important;
  }

  &:disabled {
    background-color: #d3d3d3 !important; // Light gray for disabled state
    color: #a9a9a9 !important; // Dimmed gray text
    cursor: not-allowed; // Shows a "not allowed" cursor
  }
}

.form-note-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px !important;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: red;
}
