.ServiceProviderCreate__switch__container {
  .Deleted__Toggle {
    color: rgba(0, 0, 0, 0.7) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    padding-top: 8px !important;
  }
}

.BuySell__basic__details__container {
  height: 100%;
  padding: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BuyAndSellView__basic__details__container {
  height: 100%;
  padding: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image__arrow__icon {
  cursor: pointer;
}
