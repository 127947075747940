@import "../../utils/style-constants";

.TableComp__table {
  border: 1px solid #e0e0e0;
  min-width: 650px;
}

.TableComp__table__header {
  font-weight: bold !important;
  white-space: break-spaces;
    text-overflow: ellipsis;
  }
  
  .No_Break_TableComp__table__header {
    font-weight: bold !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TableComp__table__header__Actions {
  font-weight: bold !important;
  padding-left: 25px !important;
}

.TableComp__table__cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TableComp__table__cell__Content {
  display: flex;
  align-items: center;
  .desc-data {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
}

.Service_Provider_phone_number_TableComp__table__cell__Content {
  display: flex;
  align-items: center;
  .desc-data {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    white-space: nowrap;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
}

.TableComp__table__cell__badge {
  margin-left: 32px;
  span {
    white-space: nowrap;
  }
}

.TableComp__table__icon {
  font-size: 25px;
}

.NotFound__container {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.NotFound__link {
  text-decoration: none;
}

.Tabs {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: "100%";
  min-height: 30px;
  margin: 30px auto;
}

.Review__Service__Provider {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $deep_ocean_blue;
  }
}

.Delete__Reason__Modal {
  display: flex;
  justify-content: center;
  color: $deep_ocean_blue;
  font-weight: bold !important;
  padding-bottom: 10px;
}

.custom-label-span {
  color: red !important;
}

.Confirmation__Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: white;
  box-shadow: 24px;
  padding: 30px;
  border-radius: 8px;
}

.DeleteReason__Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  box-shadow: 24px;
  padding: 30px;
  border-radius: 8px;
}

.Select__Language__Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 24px;
  padding: 30px;
  border-radius: 8px;
}

.DeleteReason__Modal__Close {
  margin-left: auto !important;
  margin-right: -22px !important;
}

.business__request {
  height: 83%;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}

.flag__popover {
  font-size: 12px;
  padding: 10px;
}

.flag__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: white;
  box-shadow: 24px;
  padding: 30px;
  border-radius: 8px;
}

.flag__text {
  color: $deep_ocean_blue;
  margin-bottom: 5px;
  border-bottom: 1px solid $deep_ocean_blue;
}

.modal__container {
  .modal__view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    border: none;
    outline: none;
    padding: 20px;
    cursor: pointer;
    max-height: 90%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $deep_ocean_blue;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.view__modal__badge {
  border: 2px solid $deep_ocean_blue;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 20px;
  color: $deep_ocean_blue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor_pointer {
  cursor: pointer;
}

.view__modal__badge {
  border: 2px solid $deep_ocean_blue;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 20px;
  color: $deep_ocean_blue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_underline {
  text-decoration: underline;
}
