@media screen and (max-width: 360px) {
  .nivasi-icon {
    width: 120px !important;
  }
}

.footer {
  background-color: #333737;
  width: 100%;
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
}

.community-thrives {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 60px;
  line-height: 64px;
  color: #ee693e;
  padding-bottom: 40px;
}
@media only screen and (max-width: 820px) {
  .community-thrives {
    font-size: 55px;
  }
}

.rights-reserved {
  font-family: "DM Sans";
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: white;
}
.rights-reserved a {
  text-decoration: none;
  color: white;
}

.support-section {
  background-color: #565f5f;
  text-align: center;
  border-radius: 25px;
  padding: 30px;
  color: white;
}

.support-text {
  font-family: "DM Sans";
  font-size: 50px;
  font-weight: 700;
  line-height: 35.64px;
  color: white;
}

.support-contents {
  font-family: "DM Sans";
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.support-content {
  color: white;
  font-weight: 700;
}

.privacy-list {
  list-style-type: none;
  padding-left: 15px;
}

.privacy-list li {
  position: relative;
  padding-left: 1.2em;
}

.privacy-list li::before {
  content: "*";
  position: absolute;
  left: 0;
  font-size: 1.2em;
  top: 4px;
}

.info-mail {
  color: black;
  text-decoration: none;
}

.accept-button {
  text-align: center;
  background-color: black;
  color: white;
  background: #000;
}

.thankyou-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 4px;
  background-color: wheat;
  color: black;
}

.thankyou-text {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 30px;
}
.thankyou-message {
  font-family: "DM Sans";
  font-weight: 500;
}
.modal-close-content {
  background-color: transparent;
  border: none;
  margin-right: auto;
  margin-left: auto;
}
.modal-close-header {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 25px;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
  margin-bottom: 10px;
  justify-content: center;
}
.close-icon {
  margin-right: auto;
  margin-left: auto;
}
.social-media {
  font-family: "DM Sans";
  font-size: 31.13px;
  font-weight: 700;
  line-height: 35.64px;
  color: #ee693e;
  text-align: center;
  padding: 20px;
}

.social-media-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .social-media-icons {
    display: flex;
    flex-direction: row;
    gap: 110px;
    justify-content: center;
  }
}

.terms-section {
  margin-top: 150px;
}
.privacy-terms-heading {
  font-family: "DM Serif Display";
  font-size: 72px;
  font-weight: 400;
  line-height: 72px;
}
.privacy-terms-heading-tamil {
  font-family: "DM Serif Display";
  font-size: 27px !important;
  font-weight: 400;
  line-height: 72px;
}
.privacy-terms-date {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
.privacy-terms-number {
  color: #858585;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 700;
}
.privacy-terms-title {
  font-family: "DM Sans";
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 500;
}
.privacy-terms-description {
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}
@media screen and (max-width: 1024px) {
  .terms-section {
    margin-top: 50px;
  }
}

.spinner {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  position: "fixed";
  top: 0;
  left: 0;
  width: "100vw";
  height: "100vh";
  z-index: 1000;
}

.language-dropdown {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
