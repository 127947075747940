@import "../../utils/style-constants";

.Home {
  height: 100vh;
  display: flex;
  align-items: center;
}

.Home__container {
  width: 45% !important;
  margin: auto;
  @media (max-width: 1560px) {
    width: 50% !important;
  }
  @media (max-width: 1400px) {
    width: 54% !important;
  }
  @media (max-width: 1300px) {
    width: 59% !important;
  }
  @media (max-width: 1200px) {
    width: 66% !important;
  }
  @media (max-width: 1070px) {
    width: 70% !important;
  }
}

.Home__item__container {
  height: 180px;
  width: 180px;
  border-radius: 30px !important;
  background-color: $deep_ocean_blue !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
}

.Home__left__items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}

.Home__right__items {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  padding: 20px;
}

.Home__item__icon__container {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.Home__item__icon {
  color: $white;
  height: 80px !important;
  width: 80px !important;
}

.Home__item__text {
  text-align: center;
}
