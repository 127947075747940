@import "../../utils/style-constants";

.ServiceProviderCreate__Map__container {
  position: relative;
}

.ServiceProviderCreate__checkbox__container {
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 40px;
  background-color: $white;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ServiceProviderCreate__location__picker__container {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 6px;
  margin-right: 6px;
}

.ServiceProviderCreate__location__picker__unchecked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}

.ServiceProviderCreate__location__picker__unchecked__icon {
  height: 50px !important;
  width: 50px !important;
  margin: 0;
}

.ServiceProviderCreate__location__picker__unchecked__typography {
  font-size: 1rem;
  font-weight: 400;
}

.ServiceProviderCreate__location__link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
}

.ServiceProviderCreate__switch__container {
  display: flex;
  flex-direction: row !important;
}

.ServiceProviderCreate__delivery_switch__container {
  display: flex;
  flex-direction: row !important;
}

.ServiceProviderCreate__image__picker__container {
  width: 100%;
  height: 200px !important;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  input {
    display: none;
  }

  label {
    width: 100%;
  }
}

.Service__terms__details__container {
  height: 100px;
}

.Upload__pdf__filename {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 10px;

  &:hover {
    border: 1px solid $deep_ocean_blue;
  }
overflow: 'hidden';
white-space: 'nowrap';
text-overflow : 'ellipsis'
}

.ServiceProviderCreate__image__picker__button {
  width: 100% !important;
  color: rgba(0, 0, 0, 0.7) !important;
  text-transform: none;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ServiceProviderCreate__image__picker__add__photo__icon {
    height: 50px;
    width: 50px;
    margin: 0;
  }
}

.upload__input {
  opacity: 0.5;
}

.ServiceProviderCreate__image__picker__button:hover {
  color: $deep_ocean_blue !important;
}

.ServiceProviderCreate__image__cropper__modal {
  display: flex;
  align-items: center;
}

.ServiceProviderCreate__image__cropper__modal__body {
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  background-color: $white;
}

.ServiceProviderCreate__image__cropper__slider {
  color: $deep_ocean_blue !important;
}

.ServiceProviderCreate__image__preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ServiceProviderCreate__image__preview__icon__container {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .icon__color {
    color: $white;
  }
}

.ServiceProviderCreate__image__preview__image {
  position: absolute;
  max-height: 50%;
  max-width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ServiceProviderView__location__link {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $deep_ocean_blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.autocomplete-single {
  background: #ebebeb;
  border-radius: 1rem;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
}

.day-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  /* Section for Days with Checkboxes */
  .checkbox-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    .day-item {
      label {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
    }
  }

  /* Section for Time Slots */
  .time-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    .time-slot {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: bold;

      input {
        font-size: 14px;
      }
    }
  }
}

.schedule-card {
  font-family: "DM Sans";
  color: #333;
  // margin: 20px auto;
   padding-top: 10px;
  max-width: 100%;
  // border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  outline: #ccc;
}

.schedule-header {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-family: "DM Sans";
  border-bottom: none;
}

.schedule-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px 30px;
  align-items: center;
}

.schedule-item {
  border-bottom: none;
  padding-top: 10px;
}

.schedule-item:last-child {
  border-bottom: none;
}

.schedule-day {
  font-family: "DM Sans";
  font-weight: bold;
  color: #003350;
  border-bottom: none;
}

.schedule-time {
  font-size: 14px;
  border-bottom: none;
}

.form-note-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px !important;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: red;
}